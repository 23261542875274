import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MUIThemeProvider } from '@sezzle/sezzle-mui';
import Layout from './src/components/Layout';
import sezzleTheme from './src/templates/Page/themes';
import { GlobalWrapper } from './src/stylesheets/global';
import '@fontsource/open-sans';
import '@fontsource/comfortaa';
import '@fontsource/nunito-sans';
import { getCookiePreferences } from '../../library/shared-ui/components/CookiePreferenceBanner/utils';
import { optGTM } from '../../library/shared-ui/components/CookiePreferenceBanner/injections';

export const wrapPageElement = ({ element, props }) => {
  return props.pageContext.layout ? (
    <ThemeProvider theme={sezzleTheme}>
      <GlobalWrapper />
      <MUIThemeProvider>
        <Layout>{element}</Layout>
      </MUIThemeProvider>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={sezzleTheme}>
      <GlobalWrapper />
      <MUIThemeProvider>{element}</MUIThemeProvider>
    </ThemeProvider>
  );
};

// for safari images to render properly
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }

  try {
    const { allowedAll } = await getCookiePreferences();
    optGTM(allowedAll);
  } catch (error) {
    console.error(error);
  }
};
