/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import Columns from './Columns';
import FooterRow from './FooterRow';
import InfoColumn from './InfoColumn';
import { Footer as FooterProps } from './types/types';
import CookiePreferenceBanner from '../CookiePreferenceBanner';

const StyledFooter = styled.footer`
  background: ${(props: any) => props.theme.footerBackground};
  display: flex;
  flex-direction: column;

  .container {
    padding: 60px 30px;
    flex: 1;
  }

  .container__footer {
    width: 100%;
  }

  .container__footer--flex {
    display: flex;

    @media (max-width: 576px) {
      flex-wrap: wrap;
    }

    .container {
      padding: 1rem 0;
    }
  }

  .wrapper__column {
    flex-wrap: wrap;
    display: flex;
    justify-content: left;
    width: 100%;
    @media (max-width: 576px) {
      justify-content: space-between;
    }

    a {
      color: ${(props: any) => `${props.theme.primary}`};
      text-decoration: none !important;
      line-height: 2;

      &:hover,
      &:focus {
        color: ${(props: any) => `${props.theme.secondary}`};
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }
`;

const Footer = (props: FooterProps) => {
  const {
    pageContext,
    columns,
    infoColumn,
    logoContainer,
    bottomRow,
    cookieConsentBanner,
    analyticServices,
  } = props;

  return (
    <>
      <StyledFooter>
        <div className="container">
          <div className="container__footer">
            <div className="container__footer--flex">
              <InfoColumn
                fields={infoColumn}
                logo={logoContainer}
                pageContext={pageContext}
              />
              <div className="wrapper__column">
                {columns?.map((item, index) => (
                  <Columns
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    listHeader={item.listHeader}
                    list={item.list}
                    headerLevel={item.headerLevel}
                  />
                ))}
              </div>
            </div>
          </div>
          <FooterRow fields={bottomRow} />
        </div>
      </StyledFooter>
      {!!cookieConsentBanner && (
        <CookiePreferenceBanner
          {...cookieConsentBanner}
          analyticServices={analyticServices}
        />
      )}
    </>
  );
};

export default Footer;
