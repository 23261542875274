import React from 'react';
import styled from 'styled-components';
import renderComponent from '../renderSetOf';
import { setColor } from '../../utils/setColor';

export interface SetOfProps {
  fields: any;
}

interface StyledProps {
  backgroundColor: any;
  elementBorderColor: string;
  borderRadius: string;
}

export const SetOfStyled = styled.div<StyledProps>`
  background-color: ${(props: any) =>
    props.backgroundColor !== 'none'
      ? setColor(props.backgroundColor, props)
      : 'inherit'};
  border-radius: ${(props: any) =>
    props.borderRadius !== null && props.borderRadius !== undefined
      ? props.borderRadius
      : '5px'};
  font-family: ${(props: any) => `${props.theme.bodyFont}`};

  .row {
    padding: ${(props: any) =>
      props.elementBorderColor !== null &&
      props.elementBorderColor !== undefined
        ? '0.5rem'
        : '0'};
  }

  .element-border {
    border: ${(props: any) =>
      props.elementBorderColor !== null &&
      props.elementBorderColor !== undefined
        ? `2px solid ${setColor(props.elementBorderColor, props)}`
        : 'none'};
    border-radius: ${(props: any) =>
      props.borderRadius !== null && props.borderRadius !== undefined
        ? props.borderRadius
        : '5px'};
    margin: 0.5rem;
  }

  .padding {
    &--0 {
      padding: 0;
    }

    &--1x-half {
      padding: 0.5rem 0;
    }

    &--1x {
      padding: 1rem 0;

      &-half {
        padding: 0.5rem 0;
      }

      &-sm {
        @media (max-width: 768px) {
          padding: 1rem !important;
          margin-bottom: 0 !important;
        }
        &-half {
          @media (max-width: 768px) {
            padding: 0.5rem !important;
            margin-bottom: 0 !important;
          }
        }
      }

      &-surround {
        padding: 1rem;
      }
    }

    &--2x {
      padding: 2rem 0;

      &-sm {
        @media (max-width: 768px) {
          padding: 2rem !important;
          margin-bottom: 0 !important;
        }
      }

      &-surround {
        padding: 2rem;
      }
    }

    &--3x {
      padding: 3rem 0;

      &-sm {
        @media (max-width: 768px) {
          padding: 3rem !important;
          margin-bottom: 0 !important;
        }
      }

      &-surround {
        padding: 3rem;
      }
    }
  }

  .vertical-align {
    margin-top: auto;
    margin-bottom: auto;
  }

  .align {
    &--center {
      text-align: center;
    }
  }

  .sm {
    display: block;
    @media (min-width: 769px) {
      display: none;
    }
  }

  .lg {
    display: block;
    @media (max-width: 769px) {
      display: none;
    }
  }

  .col-xs-12:not(:last-child),
  .col-xs-6:not(:last-child) {
    @media (max-width: 768px) {
      margin-bottom: 2rem;
    }
  }
`;

const SetOf = (props: SetOfProps) => {
  const { fields } = props;

  const renderColumns = (column) => {
    switch (column) {
      case 1:
        return '12';
      case 2:
        return '6';
      case 3:
        return '4';
      case 4:
        return '3';
      case 6:
        return '2';
      case 12:
        return '1';
      default:
        return '12';
    }
  };

  const mobileElementChange =
    fields.visibleElementsSmall &&
    fields.visibleElementsSmall !== undefined &&
    fields.visibleElementsSmall !== 0 &&
    fields.visibleElementsSmall < fields.sections?.length;

  return (
    <SetOfStyled
      data-content-type="setOf"
      backgroundColor={fields.backgroundColor}
      elementBorderColor={fields.elementBorderColor}
      borderRadius={fields.borderRadius}
    >
      <div>
        <div
          className={`row wide center${
            fields.padding !== 'none' && fields.padding !== undefined
              ? ` ${fields.padding}`
              : ''
          }`}
        >
          {mobileElementChange &&
            fields.sections
              ?.slice(0, fields.visibleElementsSmall)
              .map((item, index) => (
                <div
                  className={`sm col-xs-${renderColumns(fields.elementsSmall)}
                col-md-${renderColumns(fields.elementsLarge)}
                ${
                  fields.elementBorderColor !== null &&
                  fields.elementBorderColor !== undefined
                    ? ' element-border'
                    : ''
                }
              ${
                fields.elementPadding !== 'none' &&
                fields.elementPadding !== undefined
                  ? ` ${fields.elementPadding}`
                  : ''
              }
              ${
                fields.elementAlignment !== 'none' &&
                fields.elementAlignment !== undefined
                  ? ` align--${fields.elementAlignment}`
                  : ''
              }
              ${
                fields.elementPaddingSmall !== 'none' &&
                fields.elementPaddingSmall !== undefined
                  ? ` ${fields.elementPaddingSmall}`
                  : ''
              }`}
                  key={index}
                >
                  {renderComponent(
                    item.sys?.contentType?.sys.id ||
                      item?.contentfulContentType,
                    item
                  )}
                </div>
              ))}
          {fields.sections?.map((item, index) => (
            <div
              className={`${mobileElementChange ? 'lg ' : ''}col-xs-${renderColumns(fields.elementsSmall)}
              col-md-${renderColumns(fields.elementsLarge)}
              ${
                fields.elementBorderColor !== null &&
                fields.elementBorderColor !== undefined
                  ? ' element-border'
                  : ''
              }
              ${
                fields.elementPadding !== 'none' &&
                fields.elementPadding !== undefined
                  ? ` ${fields.elementPadding}`
                  : ''
              }
              ${
                fields.elementAlignment !== 'none' &&
                fields.elementAlignment !== undefined
                  ? ` align--${fields.elementAlignment}`
                  : ''
              }
              ${
                fields.elementPaddingSmall !== 'none' &&
                fields.elementPaddingSmall !== undefined
                  ? ` ${fields.elementPaddingSmall}`
                  : ''
              }`}
              key={index}
            >
              {renderComponent(
                item.sys?.contentType?.sys?.id || item?.contentfulContentType,
                item
              )}
            </div>
          ))}
        </div>
      </div>
    </SetOfStyled>
  );
};

export default SetOf;
