import type { Text } from '../Text/types/types';
import type { analyticSettings } from '../Footer/types/types';

enum CookiePreferenceKey {
  acted = 'a',
  allowedAll = 'x',
}

export type CookiePreferenceConcealed = {
  [key in CookiePreferenceKey]: number;
};

export type CookiePreferenceData = {
  acted: boolean;
  allowedAll: boolean;
};

export const COOKIE_PREFERENCE = '_szlcpref';
export enum CountryCode {
  US = 'us',
  CA = 'ca',
}

export interface CookieConsentProps {
  newConsentText: Text;
  accept: string;
  decline: string;
  analyticServices?: analyticSettings;
}
