import { parse } from 'date-fns';
import { postApi, getApi } from './helper';
import { AxiosResponse } from 'axios';

const sezzlePayUrl = process.env.GATSBY_SEZZLE_PAY_API_URL;

export interface GeoIPDetails {
  city: string;
  continent: string;
  country_iso_code: string;
  country_name: string;
  ip_address: string;
  latitude: number;
  longitude: number;
  postal_code: string;
  region_iso_code: string;
  region_name: string;
}

export const submitMerchantOwnerData = async (businessDetails, owners) => {
  const url = `${sezzlePayUrl}/v1/merchant-owner-submissions`;

  const ownersData = owners.map((o) => {
    const parsedDob = parse(o.dob, 'MM/dd/yyyy', new Date());

    return {
      legal_business_name: businessDetails.legalBusinessName,
      dba: businessDetails.dba,
      business_address_line1: businessDetails.businessAddressLine1,
      business_address_line2: businessDetails.businessAddressLine2,
      business_city: businessDetails.businessAddressCity,
      business_state: businessDetails.businessAddressState,
      business_zip: businessDetails.businessAddressZip,
      entity_structure: businessDetails.entityStructure,
      state_of_incorporation: businessDetails.stateOfIncorporation,
      ein: businessDetails.ein,
      years_in_business: businessDetails.yearsInBusiness,
      category: businessDetails.category,
      website: businessDetails.website,
      annual_sales: businessDetails.annualSales,
      average_order_value: businessDetails.averageOrderValue,
      bank_account_number: businessDetails.bankAccountNumber,
      bank_routing_number: businessDetails.bankRoutingNumber,
      owner_first_name: o.firstName,
      owner_last_name: o.lastName,
      last_4_ssn: o.last4Ssn,
      dob_year: parsedDob.getFullYear(),
      dob_month: parsedDob.getMonth() + 1,
      dob_day: parsedDob.getDate(),
      email: o.email,
      phone: o.phone,
      residency_address_line1: o.residencyAddressLine1,
      residency_address_line2: o.residencyAddressLine2,
      residency_city: o.residencyAddressCity,
      residency_state: o.residencyAddressState,
      residency_zip: o.residencyAddressZip,
      percent_ownership: Number.parseInt(o.percentOwnership, 10),
    };
  });
  try {
    return await postApi(url, { owners: ownersData });
  } catch (err) {
    console.error(err);
    return new Error('Request failed');
  }
};

export const submitVisitorData = async (visitor) => {
  const url = `${sezzlePayUrl}/v3/tracking/phone-number-capture`;
  return await postApi(url, visitor);
};

export const postSezzleProCheckout = async (request) => {
  const url = `${sezzlePayUrl}/v2/sezzle-pro-checkout`;
  try {
    return await postApi(url, request);
  } catch (err) {
    console.error(err);
    return new Error('Request failed');
  }
};

export const postStripeCheckoutSuccess = async (sessionID, country) => {
  const url = `${sezzlePayUrl}/v2/sezzle-pro-checkout-success/${sessionID}/${country}`;
  try {
    return await postApi(url, null);
  } catch (err) {
    console.error(err);
    return new Error('Request failed');
  }
};

export const recordSezzleProMerchantEvent = async (merchantID) => {
  const url = `${sezzlePayUrl}/v2/sezzle-pro-merchant-event/${merchantID}`;
  try {
    return await postApi(url, null);
  } catch (err) {
    console.error(err);
    return new Error('Request failed');
  }
};

export const getGeoIpDetails = async (): Promise<
  AxiosResponse<GeoIPDetails>
> => {
  const url = `https://geoip.sezzle.com/v1/geoip/ipdetails`;
  try {
    return await getApi(url);
  } catch (err) {
    console.error(err);
    throw new Error('Request failed');
  }
};
