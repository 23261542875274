import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, IconButton, Typography } from '@sezzle/sezzle-mui';
import CloseIcon from '@mui/icons-material/Close';

import { getGeoIpDetails } from '../../../../packages/website/src/api/api';
import {
  getCookiePreference,
  populateCookiePreference,
  setCookiePreference,
} from '../CookiePreferenceBanner/utils';
import { CookieBanner } from '../CookiePreferenceBanner/styled';
import type { CookieConsentProps } from '../CookiePreferenceBanner/types';
import Text from '../../../shared-ui/components/Text';
import { handleTrackingOptions } from './injections';

const CookiePreferenceBanner = ({
  newConsentText,
  accept,
  decline,
  analyticServices,
}: CookieConsentProps) => {
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const defaultAllowedRef = useRef<boolean>(false);

  const handleCloseBanner = () => {
    setDisplayBanner(false);
  };

  useEffect(() => {
    (async () => {
      const { status, data: geoIP } = await getGeoIpDetails();
      if (status === 200) {
        const { allowedAll } = populateCookiePreference(geoIP);
        defaultAllowedRef.current = allowedAll;
      }

      const { allowedAll, acted } = getCookiePreference();
      if (!acted) {
        setDisplayBanner(true);
      }
      handleTrackingOptions(analyticServices, allowedAll);
    })();
  }, []);

  const handleAccepted = () => {
    setCookiePreference({
      acted: true,
      allowedAll: true,
    });
    handleTrackingOptions(analyticServices, true);
    handleCloseBanner();
  };
  const handleDeclined = async () => {
    setCookiePreference({
      acted: true,
      allowedAll: false,
    });
    handleTrackingOptions(analyticServices, false);
    handleCloseBanner();
  };
  const handleCloseIcon = (defaultAllowedAll: boolean) => {
    setCookiePreference({
      acted: true,
      allowedAll: defaultAllowedAll,
    });
    handleCloseBanner();
  };

  return (
    <>
      {displayBanner && (
        <CookieBanner>
          <Card
            sx={{
              borderRadius: 2,
              boxShadow: 2,
              maxWidth: '35rem',
              textAlign: 'center',
              padding: '1rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '0.5rem 0rem 0.5rem 0.5rem',
              }}
            >
              <Typography
                align="left"
                variant="caption2"
              >
                <Text {...newConsentText} />
              </Typography>
              <IconButton
                data-testid="close-icon"
                onClick={() => handleCloseIcon(defaultAllowedRef.current)}
                sx={{
                  padding: '0rem 0rem 3rem 0rem',
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Button
                onClick={handleAccepted}
                sx={{
                  maxHeight: '2.5rem',
                }}
              >
                {accept}
              </Button>
              <Button
                variant="Secondary"
                onClick={handleDeclined}
                sx={{
                  maxHeight: '2.5rem',
                }}
              >
                {decline}
              </Button>
            </Box>
          </Card>
        </CookieBanner>
      )}
    </>
  );
};

export default CookiePreferenceBanner;
